import React, { useRef } from 'react'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { Menu, MenuExportCsv, MenuExportExcel, MenuItem, MenuTableSettingToggle } from '../../../../../../shared/menu/menu'
import { useRouteMatch } from '../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { IconCalender } from '../../../../../../shared/icon/icon'
import { Column, ColumnDateTime, ColumnYesNo, TableBuilder } from '../../../../../../shared/table-builder/table-builder'
import { useParseJSON } from '../_common/hook'
// import { VisTimelinePeriod } from './vis-graph/vis-timeline-period'

export const AnalyticCustomTable = ({ title, apiList, onReady }) => {
  const { url } = useRouteMatch()

  const tableRef = useRef()
  const handleExportCsv = () => tableRef.current.exportCsv()
  const handleExportExcel = () => tableRef.current.exportExcel()
  const handleTableSettingToggle = () => tableRef.current.toggleSideBar()

  const HeaderMenu = () =>
    <Menu>
      <MenuItem Icon={IconCalender} text="timeline" to={`${url}/timeline`} show pin />
      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
      <MenuTableSettingToggle onClick={handleTableSettingToggle} show />
    </Menu>

  let parseValue = useParseJSON()

  return (
    <CardFullHeightScrollY title={title} HeaderMenu={HeaderMenu}>
      <TableBuilder ref={tableRef} apiList={apiList} sortField="capturedAt" sortDir="desc" onReady={onReady}>
        <ColumnDateTime field="capturedAt" />
        <Column field="sessionID" />
        <Column field="nodeName" header="Course" sortable />

        <Column field="topicName" />
        <Column field="topicDescription" />

        <Column field="analyticName" />

        <Column field="value" selector={row => parseValue(row.value)} />
        <Column field="type" />

        <Column field="email" />
        <Column field="firstName" />
        <Column field="lastName" />

        <ColumnDateTime field="startOn" />
        <ColumnDateTime field="endOn" />

        <Column field="os" />
        <Column field="platform" />
        <Column field="appVersion" />

        <Column field="deviceModel" />
        <Column field="deviceType" />
        <Column field="vrDeviceModel" />
        <Column field="vrDeviceName" />
        <Column field="vrDevicePresent" />
        <Column field="vrDeviceTrackingOriginMode" />
        <Column field="vrDeviceTrackingSpaceType" />
        <Column field="vrDeviceRefreshRate" />
        <Column field="graphicsCard" />
        <Column field="graphicsCardMemorySize" />
        <Column field="graphicsCardVendor" />
        <Column field="processor" />
        <Column field="processorCount" />
        <Column field="processorFrequency" />
        <Column field="systemMemorySize" />
        <ColumnYesNo field="isMultiPlayer" />

        <Column field="locationCountry" />
        <Column field="locationRegion" />
        <Column field="locationCity" />

        <Column field="groups" />
      </TableBuilder>
    </CardFullHeightScrollY>
  )
}

// const dateFormatter = params => moment(params.value).format('MM-DD-YYYY')
// const dateTimeFormatter = params => moment(params.value).format('MM-DD-YYYY h:mm:ss a')

// export const Table = ({ title, data, onReady }) => {
//   const { url } = useRouteMatch()
//   const { nodeID } = useParamsInt()

//   const apiListAnalyticPeriod = useApiListAnalyticPeriod()

//   useLoad(() => apiListAnalyticPeriod.get(`${nodeID}/node`), [])

//   const columns = useMemo(
//     () => [
//       { field: 'capturedAt', filter: FilterDate, valueFormatter: dateTimeFormatter, sort: 'desc' },
//       { field: 'topicName', filter: FilterText },
//       { field: 'analyticName', filter: FilterText },
//       { field: 'value', filter: FilterText, enablePivot: true, aggFunc: 'count' },
//       { field: 'type', filter: FilterText, enablePivot: true, aggFunc: 'count' },
//       { field: 'sessionID', filter: FilterText },

//       //session data
//       { field: 'startOn', filter: FilterDate, valueFormatter: dateFormatter },
//       { field: 'endOn', filter: FilterDate, valueFormatter: dateFormatter },
//       { field: 'os', filter: FilterText },
//       { field: 'platform', filter: FilterText },
//       { field: 'appVersion', filter: FilterText },
//       { field: 'appEnvironment', filter: FilterText },
//       { field: 'deviceModel', filter: FilterText },
//       { field: 'deviceType', filter: FilterText },
//       { field: 'vrDeviceModel', filter: FilterText },
//       { field: 'vrDeviceName', filter: FilterText },
//       { field: 'vrDevicePresent', filter: FilterText },
//       { field: 'vrDeviceTrackingOriginMode', filter: FilterText },
//       { field: 'vrDeviceTrackingSpaceType', filter: FilterText },
//       { field: 'vrDeviceRefreshRate', filter: FilterText },
//       { field: 'graphicsCard', filter: FilterText },
//       { field: 'graphicsCardMemorySize', filter: FilterText },
//       { field: 'graphicsCardVendor', filter: FilterText },
//       { field: 'processor', filter: FilterText },
//       { field: 'processorCount', filter: FilterText },
//       { field: 'processorFrequency', filter: FilterText },
//       { field: 'systemMemorySize', filter: FilterText },
//       { field: 'isMultiPlayer', filter: FilterText },

//       { field: 'email', filter: FilterText, enablePivot: true, aggFunc: 'count' },
//       { field: 'firstName', filter: FilterText, enablePivot: true, aggFunc: 'count' },
//       { field: 'lastName', filter: FilterText, enablePivot: true, aggFunc: 'count' },

//       //location data
//       { field: 'locationCountry', headerName: 'Country', filter: FilterText },
//       { field: 'locationRegion', headerName: 'Region', filter: FilterText },
//       { field: 'locationCity', headerName: 'City', filter: FilterText }
//     ],
//     []
//   )

//   const HeaderMenu = () =>
//     <Menu>
//       <MenuItem Icon={IconCalender} text="timeline" to={`${url}/timeline`} show />
//     </Menu>

//   const [range, updateRange] = useState(false)
//   const [rangeStart, updateRangeStart] = useState()
//   const [rangeEnd, updateRangeEnd] = useState()

//   // const toolPanels = useMemo(
//   //   () => [
//   //     // {
//   //     //   id: 'periods2',
//   //     //   labelDefault: 'Periods',
//   //     //   iconKey: 'menuPin',
//   //     //   toolPanel: () => <PeriodGraph onSelect={handleSelect} />
//   //     // }
//   //   ],
//   //   []
//   // )

//   const footer = useMemo(
//     () => {
//       return () =>
//         <FooterRange
//           range={range}
//           start={rangeStart}
//           end={rangeEnd}
//           onChangeRange={updateRange}
//           onChangeStart={updateRangeStart}
//           onChangeEnd={updateRangeEnd}
//         />
//     },
//     [range, rangeStart, rangeEnd]
//   )

//   const rangeData = useMemo(
//     () => {
//       if (range) {
//         let start = rangeStart || new Date('2000-01-01')
//         let end = rangeEnd || new Date('3000-01-01')
//         return data.filter(item => {
//           let capturedAt = new Date(item.capturedAt)
//           return capturedAt >= start && capturedAt < end
//         })
//       }

//       return data
//     },
//     [data, range, rangeStart, rangeEnd]
//   )

//   // const handleSelect = (start, end) => {
//   //   if (start && end) {
//   //     updateRange(true)
//   //     updateRangeStart(moment(start)._d)
//   //     updateRangeEnd(moment(end)._d)
//   //   } else {
//   //     updateRange(false)
//   //   }
//   // }

//   // const sideBar = useMemo(() => {
//   //   return {
//   //     toolPanels: [
//   //       {
//   //         id: 'periods2',
//   //         labelDefault: 'Periods',
//   //         iconKey: 'menuPin',
//   //         toolPanel: () => <PeriodGraph onSelect={handleSelect} />
//   //       }
//   //     ],
//   //     defaultToolPanel: 'columns'
//   //   }
//   // }, [])

//   return (
//     <React.Fragment>
//       <CardFullHeight title={title} HeaderMenu={HeaderMenu}>
//         <TableGrid columns={columns} data={rangeData} Footer={footer} onReady={onReady} />
//       </CardFullHeight>
//     </React.Fragment>
//   )
// }

// // const PeriodGraph = ({ onSelect }) => {
// //   // const { nodeID } = useParamsInt()
// //   // const apiListAnalyticPeriod = useApiListAnalyticPeriod()
// //   // useLoad(() => apiListAnalyticPeriod.get(`${nodeID}/node`), [nodeID])
// //   // return <VisTimelinePeriod periods={apiListAnalyticPeriod.items} onSelect={onSelect} />

// //   return <React.Fragment>TEST</React.Fragment>
// // }

// export const FooterRange = ({ range, start, end, onChangeRange, onChangeStart, onChangeEnd }) => {
//   return (
//     <div className="text-nowrap d-flex">
//       <div>
//         <Switch text="Range" value={range} onChange={onChangeRange} />
//       </div>
//       {range &&
//         <React.Fragment>
//           <div>:</div>
//           <div className="mx-2">
//             <DatePickerText text="Start" value={start} max={end} onChange={onChangeStart} />
//           </div>
//           <div>-</div>
//           <div className="mx-2">
//             <DatePickerText text="End" value={end} min={start} onChange={onChangeEnd} />
//           </div>
//         </React.Fragment>}
//     </div>
//   )
// }
