import React, { useMemo, useRef } from 'react'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { IconTable } from '../../../../../shared/icon/icon'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { useLoad } from '../../../../../shared/hooks/load'
import { Column, ColumnDateTime, ColumnNum, TableBuilder } from '../../../../../shared/table-builder/table-builder'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { useApiListReportGeneralCourse } from '../../../../../api-new/state/analytics'
import { Menu, MenuExportCsv, MenuExportExcel } from '../../../../../shared/menu/menu'
import { useApiFormNode } from '../../../../../api-new/state/content'

export const AnalyticCourseGeneral = () => {
  const { nodeID } = useParamsInt()

  const apiFormNode = useApiFormNode()
  const apiListReportGeneralCourse = useApiListReportGeneralCourse()

  useLoad(() => apiFormNode.get(nodeID), [])

  const handleReady = () => apiListReportGeneralCourse.get(`${nodeID}/node`)

  const title = useMemo(() => `Analytics General - ${apiFormNode.form.name}`, [apiFormNode.form])

  const tableRef = useRef()
  const handleExportCsv = () => tableRef.current.exportCsv()
  const handleExportExcel = () => tableRef.current.exportExcel()

  const HeaderMenu = () =>
    <Menu>
      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconTable} text="analytics general">
        <CardFullHeightScrollY title={title} HeaderMenu={HeaderMenu}>
          <TableBuilder ref={tableRef} apiList={apiListReportGeneralCourse} onReady={handleReady}>
            <Column field="email" />
            <Column field="firstName" />
            <Column field="lastName" />

            <ColumnDateTime field="startOn" />
            <ColumnDateTime field="endOn" />

            <Column field="stepName" header="Step" />
            <ColumnNum field="objectives" />
            <ColumnNum field="objectivesCompleted" />
            <ColumnNum field="attachments" />
            <ColumnNum field="attachmentsCompleted" header="Attachments Viewed" />

            <Column field="os" />
            <Column field="platform" />
            <Column field="appVersion" />
            <Column field="deviceModel" />
            <Column field="deviceType" />
            <Column field="vrDeviceModel" />
            <Column field="vrDeviceName" />
            <Column field="vrDevicePresent" />
            <Column field="vrDeviceTrackingOriginMode" />
            <Column field="vrDeviceTrackingSpaceType" />
            <Column field="vrDeviceRefreshRate" />
            <Column field="graphicsCard" />
            <Column field="graphicsCardMemorySize" />
            <Column field="graphicsCardVendor" />
            <Column field="processor" />
            <Column field="processorCount" />
            <Column field="processorFrequency" />
            <Column field="systemMemorySize" />
            <Column field="isMultiPlayer" />
            <Column field="locationCountry" />
            <Column field="locationRegion" />
            <Column field="locationCity" />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
