import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { BadgeDanger, BadgeDark, BadgeSuccess } from '../../../../shared/component/badge'
import { Menu, MenuAdd, MenuDelete, MenuEdit, MenuItem } from '../../../../shared/menu/menu'
import { MenuRecycle } from '../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnDate, ColumnMenu } from '../../../../shared/table-builder/table-builder'
import { useApiFormManageAssessment, useApiListManageAssessment } from '../../../../api-new/state/assessment'
import { IconClose, IconActivate, IconDepublish, IconUnactivate, IconView, IconEdit, IconCopy, IconList } from '../../../../shared/icon/icon'
import { useRoleOrgAssessmentManage } from '../../../../role/hook/role-org'

export function AssessmentManageList() {
  const { url } = useRouteMatch()
  const { nodeID } = useParams()

  const apiListManageAssessment = useApiListManageAssessment()
  const apiFormManageAssessmentClone = useApiFormManageAssessment('/clone')

  const roleOrgAssessmentManage = useRoleOrgAssessmentManage()

  const handleMatch = async () => await apiListManageAssessment.get(`${nodeID}/node`)

  const handleClone = async assessment => {
    let cloneAssessment = {
      assessmentID: assessment.id,
      title: `${assessment.title} - copy`
    }

    await apiFormManageAssessmentClone.post(cloneAssessment)
    await apiListManageAssessment.get(`${nodeID}/node`)
  }

  const handleActive = async assessment => {
    await apiListManageAssessment.put(`${assessment.id}/activate`, assessment)
    await apiListManageAssessment.get(`${nodeID}/node`)
  }

  const handleDeactive = async assessment => {
    await apiListManageAssessment.put(`${assessment.id}/deactivate`, assessment)
    await apiListManageAssessment.get(`${nodeID}/node`)
  }

  const columnStatus = item =>
    <React.Fragment>
      {item.publishedOn && item.active && <BadgeSuccess value="active" />}
      {item.publishedOn && !item.active && <BadgeDanger value="inactive" />}

      {!item.publishedOn && !item.active && <BadgeDark value="unpublished" />}
    </React.Fragment>

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd to={`${url}/add`} show={roleOrgAssessmentManage.accessCreate} />
      <MenuRecycle to={`${url}/recycle`} show={roleOrgAssessmentManage.accessDelete} />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="assessments" onMatch={handleMatch}>
        <CardFullHeightScrollY title="assessments" HeaderMenu={HeaderMenu}>
          <TableBuilder apiList={apiListManageAssessment}>
            <Column field="title" />
            <Column field="type" />
            <ColumnDate field="publishedOn" />
            <Column field="status" selector={row => columnStatus(row)} />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuEdit to={`${url}/edit/${row.id}`} disabled={!!row.publishedOn} show={roleOrgAssessmentManage.accessUpdate} />
                  <MenuItem Icon={IconEdit} text="assignment" to={`${url}/edit/${row.id}/assignment`} show={roleOrgAssessmentManage.accessUpdate} />
                  <MenuItem text="activate" Icon={IconActivate} onClick={() => handleActive(row)} disabled={!row.publishedOn || row.active} show />
                  <MenuItem text="deactivate" Icon={IconClose} onClick={() => handleDeactive(row)} disabled={!row.publishedOn || !row.active} show />
                  <MenuItem text="clear results" Icon={IconUnactivate} to={`${url}/edit/${row.id}/clear`} disabled={!row.hasResults} show />
                  <MenuItem text="depublish" Icon={IconDepublish} to={`${url}/edit/${row.id}/depublish`} disabled={!row.publishedOn || row.hasResults} show />
                  <MenuItem text="view" Icon={IconView} to={`${url}/view/${row.id}`} disabled={!row.publishedOn} show />
                  <MenuItem text="copy" Icon={IconCopy} onClick={() => handleClone(row)} show />
                  <MenuDelete to={`${url}/edit/${row.id}/delete/${row.id}`} show={roleOrgAssessmentManage.accessDelete} />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
