import React, { useMemo, useRef } from 'react'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { useApiListReportCustomOrg } from '../../../../../api-new/state/analytics'
import { IconTable } from '../../../../../shared/icon/icon'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { useLoad } from '../../../../../shared/hooks/load'
import { Column, ColumnDateTime, ColumnYesNo, TableBuilder } from '../../../../../shared/table-builder/table-builder'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { useApiFormUserLogin } from '../../../../../api-new/state/content'
import { Menu, MenuExportCsv, MenuExportExcel } from '../../../../../shared/menu/menu'
import { useParseJSON } from '../course/_common/hook'

export const AnalyticCustomUser = () => {
  const { userID } = useParamsInt()

  const apiFormUserLogin = useApiFormUserLogin()
  const apiListReportCustomOrg = useApiListReportCustomOrg()

  useLoad(() => apiFormUserLogin.get(userID), [])

  const handleReady = () => apiListReportCustomOrg.get(`${userID}/user`)

  const title = useMemo(() => `Analytics - ${apiFormUserLogin.form.email}`, [apiFormUserLogin.form])

  const tableRef = useRef()
  const handleExportCsv = () => tableRef.current.exportCsv()
  const handleExportExcel = () => tableRef.current.exportExcel()

  const HeaderMenu = () =>
    <Menu>
      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
    </Menu>

  let parseValue = useParseJSON()

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconTable} text="analytics">
        <CardFullHeightScrollY title={title} HeaderMenu={HeaderMenu}>
          <TableBuilder ref={tableRef} apiList={apiListReportCustomOrg} onReady={handleReady}>
            <ColumnDateTime field="capturedAt" />
            <Column field="sessionID" />
            <Column field="nodeName" header="Course" />

            <Column field="topicName" />
            <Column field="analyticName" />

            <Column field="value" selector={row => parseValue(row.value)} />
            <Column field="type" />

            <Column field="email" />
            <Column field="firstName" />
            <Column field="lastName" />

            <ColumnDateTime field="startOn" />
            <ColumnDateTime field="endOn" />

            <Column field="os" />
            <Column field="platform" />
            <Column field="appVersion" />

            <Column field="deviceModel" />
            <Column field="deviceType" />
            <Column field="vrDeviceModel" />
            <Column field="vrDeviceName" />
            <Column field="vrDevicePresent" />
            <Column field="vrDeviceTrackingOriginMode" />
            <Column field="vrDeviceTrackingSpaceType" />
            <Column field="vrDeviceRefreshRate" />
            <Column field="graphicsCard" />
            <Column field="graphicsCardMemorySize" />
            <Column field="graphicsCardVendor" />
            <Column field="processor" />
            <Column field="processorCount" />
            <Column field="processorFrequency" />
            <Column field="systemMemorySize" />
            <ColumnYesNo field="isMultiPlayer" />

            <Column field="locationCountry" />
            <Column field="locationRegion" />
            <Column field="locationCity" />

            <Column field="groups" />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
