import React, { Children, forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { TableAG } from './table-ag'
import { Footer } from './component/table-footer'
import { TableFilter } from './component/table-filter'
import { TableProvider } from './table-context'
import moment from 'moment'

export const Column = ({ field, sortable, filter, header }) => <React.Fragment />
export const ColumnNum = ({ field, sortable, filter, header }) => <React.Fragment />
export const ColumnYesNo = ({ field, sortable, filter, header }) => <React.Fragment />
export const ColumnLink = ({ field, sortable, filter, header }) => <React.Fragment />
export const ColumnDate = ({ field, sortable, filter, header }) => <React.Fragment />
export const ColumnDateTime = ({ field, sortable, filter, header }) => <React.Fragment />
export const ColumnUnixDateTime = ({ field, sortable, filter, header }) => <React.Fragment />
export const ColumnDuration = ({ field, sortable, filter, header }) => <React.Fragment />
export const ColumnMenu = ({ field, sortable, filter, header }) => <React.Fragment />

export const TableBuilder = forwardRef(({ children, apiList, hideFloatingFilter, initialFilterFn, onReady, onRowClicked, sortField, sortDir }, ref) => {
  const tableRef = useRef()

  const [order, updateOrder] = useState(null)

  useImperativeHandle(ref, () => ({
    exportCsv: () => tableRef.current.exportCsv(),
    exportExcel: () => tableRef.current.exportExcel(),
    toggleSideBar: () => tableRef.current.toggleSideBar()
  }))

  const columns = useMemo(
    () => {
      let items = Children.toArray(children)

      let columns = items.map(item => {
        let newProps = { field: item.props.field }

        if (item.props.header !== undefined) {
          newProps = { ...newProps, headerName: item.props.header }
        }

        switch (item.type.name) {
          case Column.name:
            newProps = { ...newProps, ...selectorColumn(item.props) }
            break
          case ColumnNum.name:
            newProps = { ...newProps, ...selectorColumnNum(item.props) }
            break
          case ColumnYesNo.name:
            newProps = { ...newProps, ...selectorColumnYesNo(item.props) }
            break
          case ColumnLink.name:
            newProps = { ...newProps, ...selectorColumnLink(item.props) }
            break
          case ColumnDate.name:
            newProps = { ...newProps, ...selectorColumnDate(item.props) }
            break
          case ColumnDateTime.name:
            newProps = { ...newProps, ...selectorColumnDateTime(item.props) }
            break
          case ColumnUnixDateTime.name:
            newProps = { ...newProps, ...selectorColumnUnixDateTime(item.props) }
            break
          case ColumnDuration.name:
            newProps = { ...newProps, ...selectorColumnDuration(item.props) }
            break
          case ColumnMenu.name:
            newProps = { ...newProps, ...selectorColumnMenu(item.props) }
            break
          default:
            break
          //////////////////////////////////////
        }

        return newProps
      })

      if (order) {
        let columnOrder = []
        order.forEach(colId => {
          let result = columns.find(col => col.field === colId)
          columnOrder.push(result)
        })
        return columnOrder
      }

      return columns
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [children]
  )

  const data = useMemo(
    () => {
      if (!apiList) {
        return []
      }

      return initialFilterFn ? initialFilterFn(apiList.items) : apiList.items
    },
    [initialFilterFn, apiList]
  )

  const handleDragStopped = agGrid => {
    let isHeader = agGrid.target.classList.contains('ag-header-cell')

    if (isHeader) {
      let hold = agGrid.api.getColumnState()
      let colIds = hold.map(item => item.colId)
      updateOrder(colIds)
    }
  }

  const defaultSortField = useMemo(() => (sortField || columns[0] ? columns[0].field : null), [sortField, columns])
  const defaultSortDir = useMemo(() => sortDir || 'asc', [sortDir])

  return (
    <TableProvider apiList={apiList}>
      <TableAG
        ref={tableRef}
        columns={columns}
        data={data}
        sortField={defaultSortField}
        sortDir={defaultSortDir}
        loading={apiList.loading}
        Filter={TableFilter}
        Footer={Footer}
        hideFloatingFilter={hideFloatingFilter}
        onReady={onReady}
        onRefresh={onReady}
        onRowClicked={onRowClicked}
        onDragStopped={handleDragStopped}
      />
    </TableProvider>
  )
})

/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////

const selectorColumn = props => {
  let cellRenderer = agRow => (props.selector ? props.selector(agRow.data) : agRow.value)
  return { cellRenderer, filter1: 'agTextColumnFilter', enableRowGroup: true, sortable: props.sortable, hide: props.hide, comparator: props.comparator }
}

const selectorColumnNum = props => {
  return { filter1: 'agNumberColumnFilter', sort: props.sort, hide: props.hide }
}

const selectorColumnYesNo = props => {
  let cellRenderer = agRow => (agRow.value ? <span>Yes</span> : <span>No</span>)
  return { cellRenderer, filter1: 'agSetColumnFilter', enableRowGroup: true, sort: props.sort, hide: props.hide }
}

const selectorColumnLink = props => {
  let field = props.field
  let cellRenderer = agRow => <TableLink text={agRow.data[field]} to={props.to(agRow.data)} />
  return { cellRenderer, filter1: 'agTextColumnFilter', enableRowGroup: true, sort: props.sort, hide: props.hide }
}

const selectorColumnDate = props => {
  let cellRenderer = agRow => {
    let value = props.selector ? props.selector(agRow.data) : agRow.value
    return agRow.value ? moment(value).format('MM/DD/YYYY') : 'NA'
  }
  return { cellRenderer, filter1: 'agDateColumnFilter', enableRowGroup: true, sort: props.sort, hide: props.hide, comparator: props.comparator }
}

const selectorColumnDateTime = props => {
  let cellRenderer = agRow => {
    let value = props.selector ? props.selector(agRow.data) : agRow.value
    return agRow.value ? moment(value).format('MM/DD/YYYY : hh:mm:ss a') : 'NA'
  }
  return { cellRenderer, filter1: 'agDateColumnFilter', enableRowGroup: true, sort: props.sort, hide: props.hide, comparator: props.comparator }
}

const selectorColumnUnixDateTime = props => {
  let cellRenderer = agRow => {
    let value = props.selector ? props.selector(agRow.data) : agRow.value
    return agRow.value ? moment(value).unix('MM/DD/YYYY : hh:mm:ss a') : 'NA'
  }
  return { cellRenderer, filter1: 'agDateColumnFilter', enableRowGroup: true, sort: props.sort, hide: props.hide, comparator: props.comparator }
}

const selectorColumnDuration = props => {
  let cellRenderer = agRow => {
    var diff = moment(agRow.data[props.endedAt]) - moment(agRow.data[props.beganAt])
    var duration = moment.duration(diff).asMilliseconds()
    return moment.utc(duration).format('HH:mm:ss')
  }
  return { cellRenderer, filter1: 'agTextColumnFilter', enableRowGroup: true, sort: props.sort, hide: props.hide, comparator: props.comparator }
}

const selectorColumnMenu = props => {
  let cellRenderer = agRow =>
    <div className="float-end d-flex h-100">
      {props.menu(agRow.data || {})}
    </div>

  return {
    cellRenderer,
    header: 'Menu',
    field: 'menu',
    lockPosition: 'right',
    colId: 'menu',
    aggFunc: null,
    pivot: false,
    enableRowGroup: false,
    sortable: false,
    minWidth: 170
  }
}

const TableLink = ({ text, to }) => {
  const history = useHistory()

  const handleClick = () => history.push(to)

  return (
    <span className="text-decoration-underline" onClick={handleClick}>
      {text}
    </span>
  )
}

// const dateFilterParams = {
//   comparator: (filterLocalDateAtMidnight, cellValue) => {
//     let cellDate = moment(cellValue).startOf('day')._d

//     let cellTime = cellDate.getTime()
//     let localTime = filterLocalDateAtMidnight.getTime()

//     if (localTime === cellTime) {
//       return 0
//     }

//     if (localTime > cellTime) {
//       return -1
//     }

//     if (localTime < cellTime) {
//       return 1
//     }
//   }
// }
