import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-enterprise'
import 'ag-grid-enterprise/styles/ag-grid.css'
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { usePageContext } from '../../context/page.context'
import { LicenseManager, ModuleRegistry, GridChartsModule } from 'ag-grid-enterprise'
import { ClientSideRowModelModule } from 'ag-grid-community'
import { useTableContext } from './table-context'

import 'ag-grid-enterprise'
import 'ag-grid-enterprise/styles/ag-grid.css'
import 'ag-grid-enterprise/styles/ag-theme-alpine.css'

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_KEY)
ModuleRegistry.registerModules([GridChartsModule, ClientSideRowModelModule])

export const TableAG = forwardRef(
  ({ columns, data, sortField, sortDir, loading, Filter, Footer, hideFloatingFilter, onReady, onRowClicked, onRefresh, onDragStopped }, ref) => {
    const gridRef = useRef()

    const tableContext = useTableContext()

    useEffect(
      () => {
        if (gridRef.current.api) {
          if (loading) gridRef.current.api.showLoadingOverlay()
          else gridRef.current.api.hideOverlay()
        }
      },
      [loading]
    )

    const sideBarConfig = useMemo(() => {
      return {
        toolPanels: [
          'columns'
          // {
          //   id: 'test',
          //   labelDefault: 'test',
          //   labelKey: 'test',
          //   toolPanel: () => <div>TEST</div>
          // }
        ]
      }
    }, [])

    const [sideBar, updateSideBar] = useState(null)

    useImperativeHandle(ref, () => ({
      exportCsv: () => gridRef.current.api.exportDataAsCsv(),
      exportExcel: () => gridRef.current.api.exportDataAsExcel(),
      toggleSideBar: () => {
        if (sideBar) {
          updateSideBar(null)
          gridRef.current.api.resetColumnState()
          gridRef.current.api.setGridOption('pivotMode', false)
        } else {
          updateSideBar(sideBarConfig)
        }
        return !sideBar
      }
    }))

    const [pagination, updatePagination] = useState(false)

    const pageContext = usePageContext()

    const _columns = useMemo(() => columns || [], [columns])

    const defaultColDef = useMemo(() => {
      return {
        flex: 1,
        minWidth: 100,
        resizable: true,
        // floatingFilter: !hideFloatingFilter,
        filterParams: {
          debounceMs: 100
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let handleRowClicked = agRow => onRowClicked && onRowClicked(agRow.data)

    const contanerRef = useRef()

    const handleReady = async agGrid => {
      const columnState = {
        state: [
          {
            colId: sortField,
            sort: sortDir
          }
        ]
      }

      agGrid.columnApi.applyColumnState(columnState)

      if (onReady) {
        await onReady(agGrid)
      }

      let containerWidth = contanerRef.current.offsetWidth

      let allColunmWidth = agGrid.columnApi.api.getColumns().filter(i => !i.hide).reduce((sum, column) => (sum += column.actualWidth), 0)

      if (containerWidth < allColunmWidth) {
        //removed because it appears to be the default - remove id no issues come up
        // setTimeout(() => {
        //   let allColumnIds = agGrid.api.getColumns().filter(i => !i.colDef.hide).map(column => column.getId())
        //   agGrid.api.autoSizeColumns(allColumnIds, false)
        // }, 100)
      } else {
        setTimeout(() => {
          // let hold = agGrid.api.getColumn('menu')
          // debugger
          agGrid.api.sizeColumnsToFit()
          // agGrid.api.autoSizeColumns(['menu'], true)
          // hold = agGrid.api.getColumn('menu')
        }, 100)
      }
    }

    const handlePaginationChanged = agGrid => {
      let totalNumRows = agGrid.api.getDisplayedRowCount()
      tableContext.updateDisplayedRowCount(totalNumRows)

      const currentPage = agGrid.api.paginationGetCurrentPage()
      tableContext.updateCurrentPage(currentPage)

      let currentPageRows = agGrid.api.paginationGetPageSize()
      tableContext.updatePageSize(currentPageRows)

      let totalPages = agGrid.api.paginationGetTotalPages()
      tableContext.updateTotalPages(totalPages)
    }

    return (
      <div ref={contanerRef} className="d-flex flex-column h-100">
        {Filter && <Filter gridRef={gridRef} />}
        <div className={`flex-fill p-1 ${pageContext.theme.isDark ? 'ag-theme-alpine-dark' : 'ag-theme-alpine'} h-100`}>
          <AgGridReact
            // columnMenu={'new'}
            ref={gridRef}
            columnDefs={_columns}
            rowData={data}
            defaultColDef={defaultColDef}
            enableCharts={true}
            enableRangeSelection={true}
            cacheQuickFilter={true}
            pagination={pagination}
            paginationAutoPageSize={pagination}
            onPaginationChanged={handlePaginationChanged}
            suppressPaginationPanel={true}
            onGridReady={handleReady}
            onRowClicked={handleRowClicked}
            onDragStopped={onDragStopped}
            sideBar={sideBar}
            rowGroupPanelShow={sideBar && 'always'}
            overlayLoadingTemplate={`<div class="spinner-border text-primary" role="status"><span class="visually-hidden">Loading...</span></div>`}
          />
        </div>
        {Footer && <Footer tableRef={ref} gridRef={gridRef} pagination={pagination} onPaginationChange={updatePagination} onRefresh={onRefresh} />}
      </div>
    )
  }
)
