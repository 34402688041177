import React, { useRef } from 'react'
import { IconAnalyticGeneral } from '../../../../../shared/icon/icon'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Column, ColumnDateTime, ColumnNum, TableBuilder } from '../../../../../shared/table-builder/table-builder'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { useApiListChunkReportGeneralOrg } from '../../../../../api-new/state/analytics'
import { Menu, MenuExportCsv, MenuExportExcel } from '../../../../../shared/menu/menu'

export const AnalyticOrgGeneral = () => {
  const apiListChunkReportGeneralOrg = useApiListChunkReportGeneralOrg()

  const handleReady = () => apiListChunkReportGeneralOrg.get(1000)

  const tableRef = useRef()
  const handleExportCsv = () => tableRef.current.exportCsv()
  const handleExportExcel = () => tableRef.current.exportExcel()

  const HeaderMenu = () =>
    <Menu>
      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAnalyticGeneral} text="ANALYTICS GENERAL">
        <CardFullHeightScrollY title="General Analytics" HeaderMenu={HeaderMenu}>
          <TableBuilder ref={tableRef} apiList={apiListChunkReportGeneralOrg} sortField="sessionID" sortDir="desc" onReady={handleReady}>
            <Column field="sessionID" />

            <Column field="nodeName" header="Course" />

            <Column field="email" />
            <Column field="firstName" />
            <Column field="lastName" />

            <ColumnDateTime field="startOn" />
            <ColumnDateTime field="endOn" />

            <Column field="stepName" header="Step" />
            <ColumnNum field="objectives" />
            <ColumnNum field="objectivesCompleted" />
            <ColumnNum field="attachments" />
            <ColumnNum field="attachmentsCompleted" header="Attachments Viewed" />

            <Column field="os" />
            <Column field="platform" />
            <Column field="appVersion" />
            <Column field="deviceModel" />
            <Column field="deviceType" />
            <Column field="vrDeviceModel" />
            <Column field="vrDeviceName" />
            <Column field="vrDevicePresent" />
            <Column field="vrDeviceTrackingOriginMode" />
            <Column field="vrDeviceTrackingSpaceType" />
            <Column field="vrDeviceRefreshRate" />
            <Column field="graphicsCard" />
            <Column field="graphicsCardMemorySize" />
            <Column field="graphicsCardVendor" />
            <Column field="processor" />
            <Column field="processorCount" />
            <Column field="processorFrequency" />
            <Column field="systemMemorySize" />
            <Column field="isMultiPlayer" />
            <Column field="locationCountry" />
            <Column field="locationRegion" />
            <Column field="locationCity" />

            <Column field="groups" />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
