import React, { useRef } from 'react'
import { IconAnalyticCustom } from '../../../../../shared/icon/icon'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Column, ColumnDateTime, ColumnYesNo, TableBuilder } from '../../../../../shared/table-builder/table-builder'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { useApiListChunkReportCustomOrg } from '../../../../../api-new/state/analytics'
import { Menu, MenuExportCsv, MenuExportExcel } from '../../../../../shared/menu/menu'
import { useParseJSON } from '../course/_common/hook'

export const AnalyticOrgCustom = () => {
  const apiListChunkReportCustomOrg = useApiListChunkReportCustomOrg()

  const handleReady = () => apiListChunkReportCustomOrg.get(1000)

  const tableRef = useRef()
  const handleExportCsv = () => tableRef.current.exportCsv()
  const handleExportExcel = () => tableRef.current.exportExcel()

  const HeaderMenu = () =>
    <Menu>
      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
    </Menu>

  let parseValue = useParseJSON()

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAnalyticCustom} text="ANALYTICS CUSTOM">
        <CardFullHeightScrollY title="Custom Analytics" HeaderMenu={HeaderMenu}>
          <TableBuilder ref={tableRef} apiList={apiListChunkReportCustomOrg} sortField="sessionID" sortDir="desc" onReady={handleReady}>
            <ColumnDateTime field="capturedAt" />
            <Column field="sessionID" sortable />
            <Column field="nodeName" header="Course" sortable />

            <Column field="topicName" />
            <Column field="topicDescription" />
            <Column field="analyticName" />

            <Column field="value" selector={row => parseValue(row.value)} />
            <Column field="type" />

            <Column field="email" sortable />
            <Column field="firstName" sortable />
            <Column field="lastName" sortable />

            <ColumnDateTime field="startOn" sortable />
            <ColumnDateTime field="endOn" sortable />

            <Column field="os" />
            <Column field="platform" />
            <Column field="appVersion" />

            <Column field="deviceModel" />
            <Column field="deviceType" />
            <Column field="vrDeviceModel" />
            <Column field="vrDeviceName" />
            <Column field="vrDevicePresent" />
            <Column field="vrDeviceTrackingOriginMode" />
            <Column field="vrDeviceTrackingSpaceType" />
            <Column field="vrDeviceRefreshRate" />
            <Column field="graphicsCard" />
            <Column field="graphicsCardMemorySize" />
            <Column field="graphicsCardVendor" />
            <Column field="processor" />
            <Column field="processorCount" />
            <Column field="processorFrequency" />
            <Column field="systemMemorySize" />
            <ColumnYesNo field="isMultiPlayer" />

            <Column field="locationCountry" />
            <Column field="locationRegion" />
            <Column field="locationCity" />

            <Column field="groups" />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
