import React, { useCallback, useMemo, useRef } from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { BadgeInfo, BadgeSecondary, BadgeSuccess, BadgeWarning } from '../../../../../shared/component/badge'
import {
  Menu,
  MenuActivityLog,
  MenuAdd,
  MenuDelete,
  MenuEdit,
  MenuExportCsv,
  MenuExportExcel,
  MenuItem,
  MenuRecycle,
  MenuTableSettingToggle
} from '../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { Column, ColumnDate, ColumnMenu, TableBuilder } from '../../../../../shared/table-builder/table-builder'
import moment from 'moment'
import { IconAnalytic, IconAssessment, IconAuthLogin, IconContent, IconGroup, IconUsers } from '../../../../../shared/icon/icon'
import { useApiListUserLogin } from '../../../../../api-new/state/content'
import { useRoleOrgGroupUser, useRoleOrgNodeUser, useRoleOrgUser } from '../../../../../role/hook/role-org'

const BadgeStatus = ({ userLogin }) => {
  let value = useMemo(
    () => {
      if (userLogin.invitePending) {
        return <BadgeInfo value="Invited" />
      }

      if (userLogin.loginLastAuth) {
        let now = moment()
        let lastAuth = moment(userLogin.loginLastAuth)
        let minutes = now.diff(lastAuth, 'minutes')

        if (minutes < 10) {
          return <BadgeSuccess value="Online" />
        } else if (minutes < 30) {
          return <BadgeWarning value="Away" />
        } else {
          return <BadgeSecondary value="Offline" />
        }
      }

      return <BadgeSecondary value="Offline" />
    },
    [userLogin]
  )

  return (
    <React.Fragment>
      {value}
    </React.Fragment>
  )
}

export const UserLoginList = () => {
  const apiListUserLogin = useApiListUserLogin()

  const roleOrgUser = useRoleOrgUser()
  const roleOrgNodeUser = useRoleOrgNodeUser()
  const roleOrgGroupUser = useRoleOrgGroupUser()

  const handleReady = async () => await apiListUserLogin.get()

  const tableRef = useRef()
  const handleExportCsv = () => tableRef.current.exportCsv()
  const handleExportExcel = () => tableRef.current.exportExcel()
  const handleTableSettingToggle = () => tableRef.current.toggleSideBar()

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="add users" to="/org/user/list/add" show={roleOrgUser.accessCreate} />
      <MenuRecycle to="/org/user/list/recycle" show={roleOrgUser.accessDelete} />
      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
      <MenuTableSettingToggle onClick={handleTableSettingToggle} show />
      <MenuActivityLog to="/org/user/list/activity-all" show />
    </Menu>

  const comparatorStatus = useCallback((value1, value2, node1, node2) => {
    if (node1.data.invitePending || node2.data.invitePending) {
      if (node2.data.invitePending) {
        return 1
      } else {
        return -1
      }
    }

    if (!node1.data.loginLastAuth && !node2.data.loginLastAuth) {
      return -1
    }

    let date1 = new Date(node1.data.loginLastAuth)
    let date2 = new Date(node2.data.loginLastAuth)

    if (date1 > date2) {
      return 1
    } else {
      return -1
    }
  }, [])

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconUsers} text="USERS">
        <CardFullHeightScrollY title="Users" HeaderMenu={HeaderMenu}>
          <TableBuilder ref={tableRef} apiList={apiListUserLogin} onReady={handleReady}>
            <Column field="email" />
            <Column field="firstName" />
            <Column field="lastName" />

            <ColumnDate field="createdAt" hide />

            <Column field="loginLastAuth" header="Status" selector={row => <BadgeStatus userLogin={row} />} comparator={comparatorStatus} />
            <Column field="loginLastAuth" header="Last Active" selector={row => (row.loginLastAuth ? moment(row.loginLastAuth).fromNow(true) : 'Never')} />

            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuEdit to={`/org/user/list/edit/${row.userID}`} show={roleOrgUser.accessUpdate} />
                  <MenuItem Icon={IconContent} text="contents" to={`/org/user/list/edit/${row.userID}/content`} show={roleOrgNodeUser.accessRead} />
                  <MenuItem Icon={IconGroup} text="groups" to={`/org/user/list/edit/${row.userID}/group`} show={roleOrgGroupUser.accessRead} />
                  <MenuItem
                    text="analytics custom"
                    Icon={IconAnalytic}
                    to={`/org/user/list/edit/${row.userID}/analytic-custom`}
                    show={roleOrgGroupUser.accessRead}
                  />
                  <MenuItem
                    text="analytics general"
                    Icon={IconAnalytic}
                    to={`/org/user/list/edit/${row.userID}/analytic-general`}
                    show={roleOrgGroupUser.accessRead}
                  />
                  <MenuItem
                    text="assessments"
                    Icon={IconAssessment}
                    to={`/org/user/list/edit/${row.userID}/assessment-reports`}
                    show={roleOrgGroupUser.accessRead}
                  />

                  <MenuActivityLog to={`/org/user/list/activity-id/${row.userID}`} show />
                  <MenuItem text="auth history" Icon={IconAuthLogin} to={`/org/user/list/edit/${row.userID}/auth-history`} show={roleOrgGroupUser.accessRead} />
                  <MenuDelete to={`/org/user/list/edit/${row.userID}/delete`} show={roleOrgUser.accessDelete} />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
